// i18next-extract-mark-ns-start terms-conditions-page
import React from "react";
import { useTranslation, Trans, Link } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import { List, ListItem } from "@/components/ui";
import { Layout } from "@/components/layout";

import * as styles from "./terms.module.css";

const TermsPage = () => {
  const { t } = useTranslation();
  const termsList = t("terms.list", { returnObjects: true });

  return (
    <Layout>
      <section className={styles.termsSection}>
        <h1 className={styles.title}>{t("terms.title")}</h1>
        <div className={styles.aboutPageText}>
          <Trans
            i18nKey="terms.about_page"
            components={{
              homeLink: <Link className={styles.link} to="/" title={t("main_logo_text")} />,
            }}
          />
        </div>
        <ul className={styles.list}>
          {termsList.map(({ title, description }) => (
            <li className={styles.listItem} key={title}>
              <h2 className={styles.listItemTitle}>{title}</h2>
              <div className={styles.listItemText}>
                <Trans
                  components={{
                    policyLink: <Link className={styles.link} to="/policy" title={t("policy_info.policy.title")} />,
                    ulComponent: <List />,
                    liComponent: <ListItem />,
                  }}
                >
                  {description}
                </Trans>
              </div>
            </li>
          ))}
        </ul>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "terms-conditions-page"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default TermsPage;
